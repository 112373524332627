import { render, staticRenderFns } from "./ProjectsView.vue?vue&type=template&id=4202e134&scoped=true"
import script from "./ProjectsView.vue?vue&type=script&setup=true&lang=js"
export * from "./ProjectsView.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4202e134",
  null
  
)

export default component.exports